<template>
  <v-app-bar
    class="top-nav-bar"
    flat
    height="70"
  >
    <v-app-bar-nav-icon
      class="top-nav-bar__hamburger"
      flat
      size="70"
      rounded="0"
      data-test="navbar-hamburger-icon"
      @click.stop="toggleDrawer"
    />

    <router-link
      v-if="!isSmallViewport"
      data-test="logo"
      :to="routes('dashboard')"
      @click="hideDrawer"
    >
      <img
        class="top-nav-bar__logo"
        :src="isMediumViewport ? icon : fullSvg"
      />
    </router-link>

    <template #append>
      <a
        v-if="activeOrganisation.redirect_url"
        class="top-nav-bar__icon"
        target="_blank"
        rel="noopener noreferrer"
        :href="activeOrganisation.redirect_url"
        aria-label="Redirect to organisation"
      >
        <ActionButtonIcon>
          <ImageMain
            :size="2.4"
            is-circle
            :url="activeOrganisation.redirect_logo"
          />
        </ActionButtonIcon>
      </a>

      <ButtonNotifications
        class="top-nav-bar__icon"
        :new-notifications-count="newNotificationsCount"
        @notifications-are-seen="() => $emit('request-unread-notifications')"
        @click="hideDrawer"
      />

      <router-link
        class="top-nav-bar__icon"
        :to="routes('chat')"
        @click="hideDrawer"
      >
        <ActionButtonIcon data-test="message">
          <IconComponent name="chat" />
          <BadgeComponent
            v-if="newMessagesCount"
            class="top-nav-bar__badge"
            :count="newMessagesCount"
            data-test="message-badge"
            variant="critical"
          />
        </ActionButtonIcon>
      </router-link>

      <ButtonOrganisations
        v-if="isProfessional"
        @click="hideDrawer"
      />
    </template>
  </v-app-bar>
</template>

<script src="./navigationTopBar.js" />

<style lang="scss">
.top-nav-bar {
  border-bottom: .1rem solid $neutral-20;
  height: $top-navigation-height;
  overflow: inherit;

  &__hamburger {
    cursor: pointer;
    color: $brand-50;
    display: flex;
    margin-inline-start: 0 !important;

    .v-icon {
      --v-icon-size-multiplier: 1.2;
    }
  }

  &__logo {
    height: 3.6rem;
    margin-left: .8rem;
  }

  &__icon {
    cursor: pointer;
    position: relative;
    margin-left: .4rem;
    width: 3.6rem;
    height: 3.6rem;
    justify-content: center;

    @include media-breakpoint-up(md) {
      margin-left: .8rem;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 1.6rem;
    }

    .icon-component {
      color: $brand-50;
      display: flex;
      width: 2rem;
      height: 2rem;
    }

    &:first-child {
      margin-left: 0;
    }

    .btn-icon {
      padding: 0.8rem;
    }
  }

  &__badge {
    position: absolute;
    top: -0.2rem;
    right: -0.2rem;
  }

  .v-toolbar__append {
    margin-inline: auto 2.4rem;
  }
}
</style>
