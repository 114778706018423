const websitePrivacyGBLocales = {
  en: 'https://gezondeboel.nl/privacystatement-english/',
  nl: 'https://gezondeboel.nl/privacystatement/',
};

const brandsMap = {
  therapieland: {
    name: 'Therapieland',
    vimeoId: 373340845,
    websiteAccountChanges: 'https://therapieland.nl/wijzigingen/',
    websitePrivacy: 'https://therapieland.nl/privacystatement/',
    websiteRegister: 'https://therapieland.nl/aanvragenproefaccount/',
    websiteResearch: 'https://mijn.therapieland.nl/research/',
    websiteTerms: 'https://therapieland.nl/algemene-en-gebruikersvoorwaarden/',
    websiteUrl: 'https://www.therapieland.nl',
    emailInfo: 'info@therapieland.nl',
    phoneInfo: '+31207712848',
  },
  gezondeboel: {
    name: 'Gezondeboel',
    vimeoId: 290222645,
    websiteAccountChanges: 'https://gezondeboel.nl/wijzigingen/',
    websitePrivacy: websitePrivacyGBLocales[localStorage.getItem('lang') || 'nl'],
    websiteRegister: 'https://gezondeboel.nl/registeren/',
    websiteResearch: 'https://mijn.gezondeboel.nl/research/',
    websiteTerms: 'https://gezondeboel.nl/algemene-en-gebruikersvoorwaarden/',
    websiteUrl: 'https://www.gezondeboel.nl',
    emailInfo: 'info@gezondeboel.nl',
    phoneInfo: '+31208545108',
  },
};

export default brandsMap[import.meta.env.VITE_BRAND || 'therapieland'];
