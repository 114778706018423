const phoneFormatter = {
  en: (phoneNumber) => phoneNumber.replace(/\+31(\d{2})(\d{7,8})/, '(+31) $1 $2'),
  nl: (phoneNumber) => {
    // Remove the country code and leading zero
    const localNumber = phoneNumber.replace('+31', '0');

    // Format the number
    const formattedNumber = localNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2$3');

    return formattedNumber;
  },
};

export default (phoneNumber = '', countryKey = 'nl') => phoneFormatter[countryKey](phoneNumber);
